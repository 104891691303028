import snakeCase from 'lodash.snakecase'
import compose from 'ramda/es/compose'
import join from 'ramda/es/join'
import split from 'ramda/es/split'
import { enumToOptions, toObjectsKeys } from './enum'
import { capitalize } from './formatters'

export const baseUrl = `/api/v1`
export const baseUrl2 = `/api/v2`
export const baseUrl3 = `/api/v3`
export const tmsBaseUrl = `/api`
export const X_REQUEST_LOCK = 'I8uG2k0Fax31vG'

export const transactionTypes = {
  0: 'Bill Payment',
  1: 'Cash Deposit',
  2: 'Cash Withdrawal',
  3: 'Wallet Deposit',
  4: 'Wallet Withdrawal',
  5: 'Commission ToWallet',
  6: 'Wallet Transfer',
  7: 'Reversal',
  8: 'Dispense ErrorRefund',
}

export const agentTransactionStatus = ['Pending', 'Failed', 'Successful', 'Logged']

export const terminalStatus = {
  0: 'In the Office',
  1: 'On the Field',
  2: 'On Repair',
  3: 'Damaged',
  4: 'Lost',
}

export const pricingCategoryItemChargeTypes = [
  'PercentageWithMinMax',
  'RangeWithFlatCharge',
  'RangeWithPercentageCharge',
]

export const pricingCategoryItemChargeTypesOptions = enumToOptions(pricingCategoryItemChargeTypes).map((op) => ({
  ...op,
  name: compose(capitalize, join(' '), split('_'), snakeCase)(op.name),
}))

export const chargeTypeEnum = toObjectsKeys(pricingCategoryItemChargeTypes)

export function transactionStatusString(num) {
  return agentTransactionStatus[+num] || `Invalid status ${num}`
}

export function terminalStatusString(num) {
  return terminalStatus[+num] || `Invalid status ${num}`
}

export const agentTransactionStatusEnum = Object.entries(agentTransactionStatus).reduce(
  (acc, [key, value]) => ({ ...acc, [value]: +key }),
  {}
)

export const chartConfig = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}
export const withMaxYValue = (value) => {
  return {
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: value,
          },
        },
      ],
    },
  }
}
export const environment = {
  isDev: window.location.host.includes('localhost'),
  isStaging: window.location.host.includes('staging'),
  isDevAgain: window.location.host.includes('dev'),
  isQA: window.location.host.includes('qa'),
  isProd: window.location.host.includes('payforce.network'),
}

export const screenOptions = [
  { label: 'Transactions Page', value: 'TransHistory' },
  { label: 'Ticket Choice Page', value: 'SelectTicket' },
  { label: 'Settings Page', value: 'Settings' },
  { label: 'Security Page', value: 'Security' },
  { label: 'Profile Update Page', value: ' Complete Profile' },
  { label: "Dispense Error Form's Page", value: 'DispenseErrorForm' },
  { label: "Normal Ticket Form's Page", value: 'CreateTicket' },
  { label: 'External Link', value: 'EL' },
  { label: 'Select an option', value: '' },
]

export const DEFAULT_AGENT_STATUS = '1'
export const PAYFORCE_DB_APP_ID = 3
export const APP_VERSION = '0.20.13'

export const SENTRY_KEY = environment.isProd
  ? 'https://fd449114df094650b9a9c7812b50eaf2@o446397.ingest.sentry.io/5544606'
  : 'https://088f540e137f408da08fc985bae53614@o446397.ingest.sentry.io/5544607'
