import MarketingModule from 'Marketing/MarketingModule'
import ScrollToTop from 'ScrollToTop'
import { AgentConfigProvider } from 'agents/Agents.Context'
import { useAuthState } from 'auth/context/Auth.Context'
import { BillersProvider } from 'billers/Billers.Context'
import Layout from 'components/Layout'
import PageLoader from 'design/PageLoader'
import { AnimatePresence } from 'framer-motion'
import { GateProvider } from 'lib/gate'
import IssueReporter from 'lib/issue-reporter/IssueReporter'
import { User, roles } from 'lib/models'
import withTitle from 'lib/withTitle'
// import { ShopConfigProvider } from 'payforce-shop/shop.context'
import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { TerminalsConfigProvider } from 'terminals/Terminals.Context'
import { TransactionsConfigProvider } from 'transactions/Transactions.Context'
import ReactQueryProvider from './devtool'
import { AppConfigProvider } from './lib/App.Provider'
import Kyc from 'kyc/Kyc.Module'

const NgrModule = lazy(() => import('NQR/Ngr.module'))
const Agents = lazy(() => import('agents/AgentModule'))
const Lien = lazy(() => import('lien/Lien.Module'))
const Transactions = lazy(() => import('transactions/TransactionsModule'))
const Terminals = lazy(() => import('terminals/TerminalsModule'))
const Billers = lazy(() => import('billers/Billers.Module'))
const Reports = lazy(() => import('reports/Reports.Module'))
const DispenseErrors = lazy(() => import('dispenseErrors/DispenseErrors.Module'))
const Notification = lazy(() => import('notification/Notification.Module'))
const Campaigns = lazy(() => import('campaigns/Campaign.Module'))
const Charges = lazy(() => import('charges/Charges.Module'))
const Settings = lazy(() => import('auth/Settings'))
const AccountsModule = lazy(() => import('accounts/Accounts.Module'))
const Users = lazy(() => import('users/Users.Module'))
const Dashboard = lazy(() => import('dashboard/Dashboard.Module'))
const TMSTool = lazy(() => import('tms/TMS.Module'))
const TMSWIP = lazy(() => import('tms-v2/TMS.Module'))
const Loan = lazy(() => import('loans/Loan.Module'))
const LoanV2 = lazy(() => import('loans-v2/LoanV2.Module'))
const LoanV3 = lazy(() => import('loans-v3/Loanv3.Module'))
const Review = lazy(() => import('Review/ReviewModule'))
// const PayforceShop = lazy(() => import('payforce-shop/PayforceShop.Module'))
const ApiUsersManagement = lazy(() => import('apiUsersManagement/ApiUserManagement.Module'))
const Miscellaneous = lazy(() => import('miscellaneous/Miscellaneous.Module'))
const VirtualAccounts = lazy(() => import('virtualAccounts/Virtual.Accounts.Module.jsx'))
const Sales = lazy(() => import('sales/Sales.Module.jsx'))
const KYC = lazy(() => import('kyc/Kyc.Module.jsx'))
const LienV1 = lazy(() => import('liens-v1/LiensV1.Module.jsx'))

export default withTitle('Dashboard', App)
function App() {
  const { user } = useAuthState()

  return (
    <GateProvider user={user}>
      <IssueReporter />
      <ReactQueryProvider>
        <Layout>
          <AppConfigProvider>
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<PageLoader />}>
                <ScrollToTop />
                <Switch>
                  <Route path="/agents">
                    <AgentConfigProvider>
                      <Agents />
                    </AgentConfigProvider>
                  </Route>
                  <Route path="/api-user-management">
                    <ApiUsersManagement />
                  </Route>
                  <Route path="/nqr">
                    <NgrModule />
                  </Route>
                  <Route path="/transactions">
                    <TransactionsConfigProvider>
                      <Transactions user={user} />
                    </TransactionsConfigProvider>
                  </Route>
                  <Route path="/loans">
                    <Loan />
                  </Route>
                  <Route path="/loans-v2">
                    <LoanV2 />
                  </Route>
                  <Route path="/loans-v3">
                    <LoanV3 />
                  </Route>
                  <Route path="/marketing">
                    <MarketingModule />
                  </Route>
                  <Route path="/terminals">
                    <TerminalsConfigProvider>
                      <Terminals />
                    </TerminalsConfigProvider>
                  </Route>
                  <Route path="/dispense-errors">
                    <DispenseErrors />
                  </Route>
                  {/* <Route path="/payforce-shop">
                    <ShopConfigProvider>
                      <PayforceShop />
                    </ShopConfigProvider>
                  </Route> */}
                  <Route path="/miscellaneous">
                    <Miscellaneous />
                  </Route>
                  <Route path="/billers">
                    <BillersProvider>
                      <Billers />
                    </BillersProvider>
                  </Route>
                  {User.isNot(roles.COMMS, user) && (
                    <Route path="/reports">
                      <Reports />
                    </Route>
                  )}
                  {(User.isAdmin(user) ||
                    User.isComms(user) ||
                    User.isSuperAdmin(user) ||
                    User.isSupport(user) ||
                    User.isOperationHead(user)) && (
                    <Route path="/notification">
                      <Notification />
                    </Route>
                  )}
                  <Route path="/campaigns">
                    <Campaigns />
                  </Route>
                  <Route path="/charges">
                    <Charges />
                  </Route>
                  <Route path="/settings">
                    <Settings />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route path="/review">
                    <Review />
                  </Route>
                  <Route path="/accounts">
                    <AccountsModule />
                  </Route>
                  <Route path="/lien">
                    <Lien />
                  </Route>
                  {(User.isSuperAdmin(user) || User.isAdmin(user) || User.isOperationHead(user)) && (
                    <Route path="/users">
                      <Users />
                    </Route>
                  )}
                  <Route path="/tms">
                    <TMSTool />
                  </Route>
                  <Route path="/tms-v2">
                    <TMSWIP />
                  </Route>
                  <Route path="/virtual-accounts">
                    <VirtualAccounts />
                  </Route>
                  <Route path="/sales">
                    <Sales />
                  </Route>
                  <Route path="/kyc">
                    <KYC />
                  </Route>
                  <Route path="/liens-v1">
                    <LienV1 />
                  </Route>
                  <Redirect
                    to={
                      User.isOneOf(user, [
                        roles.SUPER_ADMIN,
                        roles.ADMIN,
                        roles.FIELD_SUPERVISOR,
                        roles.OPPERATION_HEAD,
                      ])
                        ? '/dashboard'
                        : '/agents'
                    }
                  />
                </Switch>
              </Suspense>
            </AnimatePresence>
          </AppConfigProvider>
        </Layout>
      </ReactQueryProvider>
    </GateProvider>
  )
}
