import * as React from 'react'
import Box from 'design/elements/Box'
import Divider from 'design/elements/Divider'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'

const LienForm = ({ liens }) => {
  const hasLiens = React.useMemo(() => liens?.data?.length > 0, [liens?.data?.length])

  return (
    <Box>
      <Text>LIEN</Text>
      <Divider mt="0" />
      <Spacer mt="md" />
      {hasLiens ? <Text>Liens placed</Text> : <Text>No Lien placed</Text>}
      <Spacer mb="lg" />
    </Box>
  )
}

export default LienForm
